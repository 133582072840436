import React, {Component} from 'react';
import { Nav } from '../components/nav';
import { Link } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardSubTitle, MDBCardText } from 'mdb-react-ui-kit';

class DashboardPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            drafts: [],
            comments: [],
            stats: [],
            commentstats: [],
            error: "",
            errorinfo: 0
        }
        let tok = localStorage.getItem("token");
        if (tok === null || tok.length < 300) {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.replace("/login");
        }
    }

    componentDidMount() {

        let token = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/posts/list", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
         .then(res => res.json())
         .then(
            response => {
                this.setState({
                    posts: response.data
                });
            },
            error => {
                this.setState({
                    error: error
                });
            }
         )

        fetch("https://api.blog.programix.ct8.pl/api/admin/drafts/list", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
         .then(res => res.json())
         .then(
            response => {
                this.setState({
                    drafts: response.data
                });
            },
            error => {
                this.setState({
                    error: error,
                    errorinfo: 1
                });
                localStorage.removeItem("token");
                localStorage.removeItem("name");
                window.location.replace("/login");
            }
         )
        fetch("https://api.blog.programix.ct8.pl/api/admin/comments/waiting", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({comments: response.data})});
        
        fetch("https://api.blog.programix.ct8.pl/api/admin/stats/posts/popular", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({stats: response.data})});
        
        fetch("https://api.blog.programix.ct8.pl/api/admin/stats/comments/popular", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({commentstats: response.data})});
    }

    renderinfo = () => {
        if (this.state.errorinfo === 1) {
            return <center><h1>Błąd logowania!</h1></center>
        }
    }

    renderError = () => {
        if (this.state.error !== "") {
            return <div className="alert alert-danger">
                        Błąd! {this.state.error}
                    </div>
        }
    }

    listPosts = () => {
        return this.state.posts.map(post => (
            <MDBCardText key={post.seo_link}><Link to={"/post/edit/" + post.id}>{post.name}</Link> - <i>autor: {post.author}</i></MDBCardText>
        ));
    }

    listDrafts = () => {
        return this.state.drafts.map(draft => (
            <MDBCardText key={draft.seo_link}>{draft.name} - <i><Link to={"/draft/edit/" + draft.id}>edytuj</Link></i></MDBCardText>
        ));
    }

    listComments = () => {
        return this.state.comments.map(comment => (
            <MDBCardText key={comment.id}>{comment.comment} - <i>do posta: {comment.post_name}</i></MDBCardText>
        ));
    }

    listStats = () => {
        return this.state.stats.map(stat => (
            <MDBCardText key={stat.id}>{stat.route}</MDBCardText>
        ));
    }

    listCommentsStats = () => {
        return this.state.commentstats.map(stat => (
            <MDBCardText key={stat.id}>{stat.route}</MDBCardText>
        ));
    }

    render() {
        return (
            <>
                <Nav/>
                <MDBContainer className="mt-4">
                    <center><h1>Podsumowanie</h1></center>
                        <MDBRow>
                            <MDBCol md="6">
                                <MDBCard>
                                    <MDBCardBody>
                                        <MDBCardTitle>Opublikowane posty</MDBCardTitle>
                                        <MDBCardSubTitle className='text-muted mb-3'>ostatnie</MDBCardSubTitle>
                                        {this.listPosts()}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md="6">
                                <MDBCard>
                                    <MDBCardBody>
                                        <MDBCardTitle>Stworzone szkice</MDBCardTitle>
                                        <MDBCardSubTitle className='text-muted mb-3'>utworzone przez Ciebie</MDBCardSubTitle>
                                        {this.listDrafts()}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mt-2'>
                            <MDBCol md="6">
                            <MDBCard>
                                    <MDBCardBody>
                                        <MDBCardTitle>Oczekujące komentarze</MDBCardTitle>
                                        <MDBCardSubTitle className='text-muted mb-3'>(przetestowane i nieprzetestowane przez filtr)</MDBCardSubTitle>
                                        {this.listComments()}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                            <MDBCol md="6">
                            <MDBCard>
                                    <MDBCardBody>
                                        <MDBCardTitle>Statystyka postów w miesiącu</MDBCardTitle>
                                        <MDBCardSubTitle className='text-muted mb-3'>sortowanie od najchętniej wywoływanej drogi</MDBCardSubTitle>
                                        {this.listStats()}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className='mt-2'>
                        <MDBCol md="6">
                            <MDBCard>
                                    <MDBCardBody>
                                        <MDBCardTitle>Statystyka komentarzy w miesiącu</MDBCardTitle>
                                        <MDBCardSubTitle className='text-muted mb-3'>sortowanie od najchętniej wywoływanej drogi</MDBCardSubTitle>
                                        {this.listCommentsStats()}
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        </MDBRow>
                    {this.renderinfo()}
                </MDBContainer>
            </>
        );
    }
}

export {DashboardPage};