import { React, Component } from 'react';
import { Formik, Form, Field } from 'formik';
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBInput, MDBBtn, MDBCard, MDBCardBody } from 'mdb-react-ui-kit';

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginProcess: 0,
            loginOk: 0,
            loginError: 0,
            token: ""
        }
    }

    componentDidMount() {
        let token = localStorage.getItem("token");
        if(token !== null) {
            window.location.href = "/dashboard";
        }
    }

    info = () => {
        if (this.state.loginProcess === 1) {
            return (
                <div className="alert alert-secondary" role="alert">
                    Trwa logowanie...
                </div>
            );
        } else if (this.state.loginOk === 1) {
            return (
                <div className="alert alert-success" role="alert">
                    Zalogowano
                </div>
            );
        } else if (this.state.loginError === 1) {
            return (
                <div className="alert alert-danger" role="alert">
                    Błąd! Zły email lub hasło
                </div>
            );
        }
    }

    error = () => {
        this.setState({
            loginProcess: 0,
            loginError: 1
        });
        setTimeout(() => {
            this.setState({
                loginError: 0
            });
        }, 5000);
    }
    success = () => {
        this.setState({
            loginProcess: 0,
            loginOk: 1
        });
        setTimeout(() => {
            window.location.href = "/dashboard";
        }, 3000);
    }

    render() {
        return (
            <>
            <header>
                <MDBNavbar expand='lg' light bgColor='light'>
                    <MDBContainer fluid>
                        <MDBNavbarBrand href="/">ProgramiX Blog - panel administracyjny</MDBNavbarBrand>
                    </MDBContainer>
                </MDBNavbar>
            </header>
            <MDBContainer className="mt-4">
                <center><h1>Zaloguj się</h1></center>
                { this.info() }
                <MDBCard className="w-50 mt-4 ms-auto me-auto">
                    <Formik
                        initialValues={{
                            email: "",
                            password: ""
                        }}
                        onSubmit={values => {
                            this.setState({
                                loginProcess: 1
                            });
                            let vJson = JSON.stringify(values);
                            fetch("https://api.blog.programix.ct8.pl/api/auth/login", {
                                method: "POST",
                                headers: { 'Content-Type': 'application/json' },
                                body: vJson
                            })
                             .then(function(response) {
                                return response.json();
                             })
                             .then((response) => {
                                if(response.status === "error") {
                                    console.error("Zły email lub hasło");
                                    this.error();
                                } else {
                                    localStorage.setItem("token", response.authorisation.token);
                                    localStorage.setItem("name", response.user.name);
                                    console.log("Sukces");
                                    this.success();
                                }
                             }
                             )
                        }}
                    >
                        <MDBCardBody>
                            <Form>
                            <Field as={MDBInput} type="email" name="email" id="email" label="Adres e-mail"/>
                            <br/>
                            <Field as={MDBInput} type="password" name="password" id="password" label="Hasło"/>
                            <br/>
                            <MDBBtn type="submit" block className="mb-3">Zaloguj się</MDBBtn>
                            <i>Nie pamiętasz hasła? Użyj trybu odzyskiwania</i>
                            </Form>
                        </MDBCardBody>
                    </Formik>
                </MDBCard>
            </MDBContainer>
            </>
        );
    }
}

export { LoginPage };