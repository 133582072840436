import React, { Component } from 'react';
import { Nav } from '../components/nav';
import { Formik, Field, Form } from 'formik';
import { useParams } from 'react-router-dom';
import { MDBContainer, MDBInput, MDBBtn } from 'mdb-react-ui-kit';

function withRouter(Component) {
    function ComponentWithRouter(props) {
      let params = useParams()
      return <Component {...props} params={params} />
    }
    return ComponentWithRouter
}

class EditCommentComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            comment: ""
        }
        let tok = localStorage.getItem("token");
        if (tok === null || tok.length < 300) {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.replace("/login");
        }
    }

    componentDidMount() {
        this.setState({
            id: this.props.params.id
        });
        this.getData();
    }

    getData = () => {
        let tokentoget = localStorage.getItem("token");

        fetch("https://api.blog.programix.ct8.pl/api/admin/comments/get/" + this.props.params.id, {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
         .then(res => res.json())
         .then(response => {
            this.setState({
                comment: response.data.comment
            });
         });
    }

    static getDerivedStateFromProps(nextProps) {
        return {
          id : nextProps.params.id,
        }
    }

    renderEditor = () => {
        if (this.state.comment !== "") {
            return (
                <div>
                    <Formik
                        initialValues={{
                            comment: this.state.comment
                        }}
                        onSubmit={values => {
                            let datatosend = JSON.stringify(values);
                            let tokentoput = localStorage.getItem("token");
                            fetch("https://api.blog.programix.ct8.pl/api/admin/comments/edit/" + this.state.id, {
                                method: "PUT",
                                headers: { 
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer' + tokentoput
                                },
                                body: datatosend
                            })
                             .then(res => res.json())
                             .then(response => {
                                console.log(response);
                                window.location.replace('/comments');
                            })
                        }}
                    >
                        <Form>
                            <h3>Treść komentarza</h3>
                            <Field label="Treść komentarza" component="textarea" rows="5" className="form-control" as={MDBInput} name="comment" id="comment" />
                            <MDBBtn className="mt-3" color='success' block type='submit'>Zapisz i zamknij</MDBBtn>
                        </Form>
                    </Formik>
                </div>
            );
        }
    }

    render() {
        return (
            <>
                <Nav/>
                <MDBContainer className='mt-4'>
                    <center><h1>Edycja komentarza</h1></center>
                    {this.renderEditor()}
                </MDBContainer>
            </>
        )
    }
}

const EditComment = withRouter(EditCommentComponent);
export { EditComment }