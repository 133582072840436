import { Link, NavLink } from "react-router-dom";
import React, { useState } from 'react';
import { MDBNavbar, MDBNavbarBrand, MDBIcon, MDBCollapse, MDBNavbarItem, MDBNavbarNav, MDBNavbarToggler, MDBContainer, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle } from "mdb-react-ui-kit";
function Nav() {
        const [showBasic, setShowBasic] = useState(false);
        return (
            <header>
                <MDBNavbar expand="lg" light bgColor="light">
                    <MDBContainer fluid>
                        <MDBNavbarBrand href="/">ProgramiX Blog - panel administracyjny</MDBNavbarBrand>
                        <MDBNavbarToggler aria-controls='navbar-content' aria-expanded='false' onClick={() => setShowBasic(!showBasic)}>
                            <MDBIcon icon='bars' fas />
                        </MDBNavbarToggler>
                        <MDBCollapse navbar show={showBasic}>
                            <MDBNavbarNav className="me-auto">
                                <MDBNavbarItem>
                                    <NavLink className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"} to="/posts">Posty</NavLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <NavLink className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"} to="/comments">Komentarze</NavLink>
                                </MDBNavbarItem>
                                <MDBNavbarItem>
                                    <NavLink className={({ isActive, isPending }) => isPending ? "nav-link" : isActive ? "nav-link active" : "nav-link"} to="/stats">Statystyka</NavLink>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                            <MDBNavbarNav right fullWidth={false}>
                                <MDBNavbarItem>
                                    <MDBDropdown>
                                        <MDBDropdownToggle tag="a" className="nav-link d-flex align-items-center" role="button">
                                            <img src="http://localhost:3000/person.png" className="rounded-circle" height="22" alt="User icon"/>
                                        </MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            <MDBDropdownItem>
                                                <Link className="dropdown-item" to="/logout">Wyloguj</Link>
                                            </MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBNavbarItem>
                            </MDBNavbarNav>
                        </MDBCollapse>
                    </MDBContainer>
                </MDBNavbar>
            </header>
        );
}

export {Nav};