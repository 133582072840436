import './App.css';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import { LoginPage } from './pages/loginPage';
import { DashboardPage } from './pages/dashboardPage';
import { PostListPage } from './pages/postListPage';
import { Edit } from './pages/editPage';
import { EditPost } from './pages/editPostPage';
import { LogoutPage } from './pages/logoutPage';
import { Comments as CommentsPage } from './pages/commentsPage';
import { EditComment } from './pages/editComment';
import StatsPage from './pages/statsPage';

function App() {
  return (
      <div className="App">
          <BrowserRouter>
              <Routes>
                  <Route path="/" element={<DashboardPage/>} />
                  <Route path="/login" element={<LoginPage/>} />
                  <Route path="/dashboard" element={<DashboardPage/>} />
                  <Route path="/posts" element={<PostListPage />} />
                  <Route path="/draft/edit/:id" element={<Edit/>} />
                  <Route path="/logout" element={<LogoutPage/>} />
                  <Route path="/post/edit/:id" element={<EditPost/>} />
                  <Route path="/comments" element={<CommentsPage/>} />
                  <Route path="/comment/edit/:id" element={<EditComment/>} />
                  <Route path="/stats" element={<StatsPage/>} />
                  <Route path="*" element={<h1>Nie znaleziono!</h1>} />
              </Routes>
          </BrowserRouter>
      </div>
  );
}

export default App;
