import React, { Component } from 'react';
import { Nav } from '../components/nav';
import { Formik, Field, Form } from 'formik';


import { useParams } from 'react-router-dom';
import { MDBContainer, MDBCol, MDBRow, MDBInput, MDBBtn } from 'mdb-react-ui-kit';

import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
        
import FroalaEditorComponent from 'react-froala-wysiwyg';
import 'froala-editor/js/plugins/markdown.min.js';

import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';

import 'froala-editor/js/plugins/align.min.js';

import 'froala-editor/js/third_party/embedly.min.js'

import 'froala-editor/js/plugins/link.min.js';

import 'froala-editor/js/plugins/lists.min.js';

import 'froala-editor/js/plugins/paragraph_format.min.js';
import 'froala-editor/js/plugins/paragraph_style.min.js';

import 'froala-editor/js/plugins/table.min.js';



function withRouter(Component) {
    function ComponentWithRouter(props) {
      let params = useParams()
      return <Component {...props} params={params} />
    }
    return ComponentWithRouter
  }

class EditPostPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: 0,
            content: "",
            data: [],
            name: "",
            seo_link: "",
            chars: 0,
            saved: 0,
            loaded: 0
        }
        let tok = localStorage.getItem("token");
        if (tok === null || tok.length < 300) {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.replace("/login");
        }
    }

    componentDidMount() {
        this.setState({
            id: this.props.params.id
        });
        this.getData();
    }

    static getDerivedStateFromProps(nextProps) {
        return {
          id : nextProps.params.id,
        }
    }

    

    getData = () => {
        let tokentoget = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/posts/get/" + this.state.id, {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
         .then(res => res.json())
         .then(
            response => {
                this.setState({
                    data: response.data
                });
            }
         )
        fetch("https://api.blog.programix.ct8.pl/api/admin/posts/content/" + this.state.id, {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
         .then(res => res.json())
         .then(
            response => {
                response.data.forEach(data => {
                    this.setState({
                        content: data
                    });
                })
                
            }
         )
    }

    handleOnChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    saveinfo = () => {
        if (this.state.saved === 1) {
            return (<div className="alert alert-success" role="alert">
                        Zapisano
                    </div>
            );
        }
    }

    handleChange = (event) => {
        let dataeditor = event;
        this.setState({
            content: dataeditor,
            chars: this.state.chars+1
        });
    }

 

    renderEditor = () => {
        return this.state.data.map(data => (
            <MDBContainer key={data.id} className="mt-4">
                    <center><h1>Edycja posta</h1></center>
                    <div>
                        <Formik
                            initialValues={{
                                name: data.name,
                                seo_link: data.seo_link,
                                content: data.content
                            }}
                            onSubmit={values=> {
                                if (this.state.content !== "") {
                                    values.content = this.state.content;
                                } else {
                                    values.content = data.content;
                                }
                                let tosend = JSON.stringify(values);
                                let tokentoput = localStorage.getItem("token");
                                fetch("https://api.blog.programix.ct8.pl/api/admin/posts/update/" + data.id, {
                                    method: "PUT",
                                    headers: { 
                                        'Content-Type': 'application/json',
                                        'Authorization': 'Bearer' + tokentoput
                                    },
                                    body: tosend
                                })
                                 .then(function(response) {
                                    return response.json();
                                 })
                                 .then((response) => {
                                    console.log(response);
                                    window.location.replace('/posts');
                                 })
                            }}>
                                <Form onChange={this.handleOnChange}>
                                    <MDBRow>
                                        <MDBCol md={6}>
                                            <Field as={MDBInput} name="name" id="name" label="Nazwa wpisu" />
                                        </MDBCol>
                                        <MDBCol md={6}>
                                            <Field as={MDBInput} name="seo_link" id="seo_link" label="Nazwa SEO"/>
                                        </MDBCol>
                                    </MDBRow>
                                    <p className="mt-4">Treść wpisu</p>
                                    <div className="form-outline">
                                        
                                    <FroalaEditorComponent
                                        tag='textarea'
                                        model={this.state.content}
                                        onModelChange={this.handleChange}
                                    />
                                    </div>
                                    <br/>
                                    <MDBBtn type='submit' block color='success'>Zapisz i zamknij</MDBBtn>                    
                                </Form>
                        </Formik>
                    </div>
                </MDBContainer>
        ));
    }

    render() {
        return (
            <>
                <Nav/>
                {this.renderEditor()}
                {this.saveinfo()}
            </>
        );
    }
}

const EditPost = withRouter(EditPostPage);
export {EditPost};