import React, {Component} from "react";
import { Nav } from "../components/nav";
import { Link } from "react-router-dom";
import { MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBRow, MDBCol, MDBBtn, MDBContainer, MDBTable, MDBTableHead, MDBTableBody, MDBModal, MDBModalBody, MDBModalDialog, MDBModalFooter, MDBModalHeader, MDBModalTitle, MDBModalContent } from "mdb-react-ui-kit";

class PostListPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeconf: 0,
            toDelete: 0,
            delDraft: 0,
            page: 1,
            total_pages: 1,
            posts: [],
            drafts: [],
            error: ""
        };

        let tok = localStorage.getItem("token");
        if (tok === null || tok.length < 300) {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.replace("/login");
        }
    }

    componentDidMount() {
        this.fetching();
    }

    fetching = () => {
        let token = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/posts/list", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
         .then(res => res.json())
         .then(
            response => {
                this.setState({
                    posts: response.data,
                    total_pages: response.meta.last_page
                });
            },
            error => {
                this.setState({
                    error: error
                });
            }
         )
        fetch("https://api.blog.programix.ct8.pl/api/admin/drafts/global", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
         .then(res => res.json())
         .then(
            response => {
                this.setState({
                    drafts: response.data
                });
            },
            error => {
                this.setState({
                    error: error
                });
            }
         )
    }


    RenderConfirmation = () => {
        if (this.state.activeconf === 1) {
            return (
                <MDBModal show={true}>
                    <MDBModalDialog>
                        <MDBModalContent>
                            <MDBModalHeader>
                                <MDBModalTitle>Usuń post</MDBModalTitle>
                                <button type="button" className="btn-close" data-mdb-dismiss="modal" aria-label="Close"></button>
                            </MDBModalHeader>
                            <MDBModalBody>Czy na pewno chcesz usunąć ten post?</MDBModalBody>
                            <MDBModalFooter>
                                <MDBBtn color="secondary" onClick={this.noDelete}>Nie</MDBBtn>
                                <MDBBtn color="danger" onClick={this.delete}>Usuń</MDBBtn>
                            </MDBModalFooter>
                        </MDBModalContent>
                    </MDBModalDialog>
                </MDBModal>
            );
        }
    }

    noDelete = (e) => {
        this.setState(
            {
                activeconf: 0,
                toDelete: 0
            }
        )
    }
    delete = (e) => {
        this.setState(
            {
                activeconf: 0
            }
        )
        let toDelete = this.state.toDelete;
        let delDraft = this.state.delDraft;
        let tokentodelete = localStorage.getItem("token");

        if (delDraft === 1) {
            fetch("https://api.blog.programix.ct8.pl/api/admin/drafts/publish/" + toDelete, {
                method: "POST",
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + tokentodelete
                }
            })
            .then(function(response) {
                return response.json();
            })
            .then((response) => {
                this.fetching();
                    fetch("https://api.blog.programix.ct8.pl/api/admin/posts/delete/" + response.data.id, {
                        method: "DELETE",
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer' + tokentodelete
                        }
                    })
                    .then(function(response) {
                        return response.json();
                    })
                    .then((response) => {
                        console.log(response);
                    }
                    );
            })
        } else {
            fetch("https://api.blog.programix.ct8.pl/api/admin/posts/delete/" + toDelete, {
                method: "DELETE",
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer' + tokentodelete
                }
            })
            .then(function(response) {
                return response.json();
            })
            .then((response) => {
                console.log(response);
            }
            );
        }
        
        this.fetching();
        setTimeout(() => {
            this.fetching();
        }, 5000);
        this.setState(
            {
                activeconf: 0,
                toDelete: 0,
                delDraft: 0
            }
        );
    }


    confirm = (e, value) => {
        this.setState(
            {
                activeconf: 1,
                toDelete: value,
                delDraft: 0
            }
        );

    }

    confirmDraft = (e, value) => {
        this.setState(
            {
                activeconf: 1,
                toDelete: value,
                delDraft: 1
            }
        );
    }

    publish = (e, value) => {
        let tokentopublish = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/drafts/publish/" + value, {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentopublish
            }
        })
         .then(function(response) {
            return response.json();
         })
         .then((response) => {
            this.fetching();
            console.log(response);
         })
    }

    renderDrafts = () => {
        return this.state.drafts.map(draft => (
            <tr key={draft.seo_link}>
                <td>{draft.name}</td>
                <td>{draft.author}</td>
                <td>{draft.updated_at}</td>
                <td>
                    <Link to={"/draft/edit/" + draft.id}>Edytuj</Link>
                    <MDBBtn color="primary" style={{marginLeft: "20px"}} onClick={(event) => this.publish(event, draft.id)}>Publikuj</MDBBtn>
                    <MDBBtn color="danger" style={{marginLeft: "20px"}} onClick={(event) => this.confirmDraft(event, draft.id)}>Usuń</MDBBtn>
                </td>
            </tr>
        ))
    }

    renderPosts = () => {
        return this.state.posts.map(post => (
            <tr key={post.seo_link}>
                <td>{post.name}</td>
                <td>{post.author}</td>
                <td>{post.desc}</td>
                <td>
                    <Link to={"/post/edit/" + post.id}>Edytuj</Link>
                    <MDBBtn color="danger" style={{marginLeft: "20px"}} onClick={(event) => this.confirm(event, post.id)}>Usuń</MDBBtn>
                </td>
            </tr>
        ))
    }

    create = (e) => {
        let tokentocreate = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/drafts/create", {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentocreate
            }
        })
         .then(function(response) {
            return response.json();
         })
         .then((response) => {
            this.fetching();
            setTimeout(() => {
                this.fetching();
            }, 5000);
         })
    }

    renderPagination = () => {
        let pagination = [];
        for (let i = 1; i<= this.state.total_pages; i++) {
            pagination.push(
                <MDBPaginationItem onClick={() => this.updatePage(i)}>
                    <MDBPaginationLink>{i}</MDBPaginationLink>
                </MDBPaginationItem>
            );
        }
        return pagination;
    };

    updatePage = (id) => {
        let token = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/posts/list?page=" + id, {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + token
            }
        })
         .then(res => res.json())
         .then(
            response => {
                this.setState({
                    posts: response.data,
                    total_pages: response.meta.last_page
                });
            },
            error => {
                this.setState({
                    error: error
                });
            }
         )
    }

    render() {
        return (
            <>
                <Nav/>
                <MDBContainer className="mt-4">
                    <center><h1>Lista postów</h1></center>
                    <MDBRow>
                        <MDBCol md="6"><h3>Szkice (wszystkie)</h3></MDBCol>
                        <MDBCol md="6"><MDBBtn className="float-end" onClick={(event) => this.create(event)}>Utwórz nowy</MDBBtn></MDBCol>
                    </MDBRow>
                    <MDBTable className="text-center">
                    <MDBTableHead>
                        <tr>
                            <th scope="col">Nazwa szkicu</th>
                            <th scope="col">Autor</th>
                            <th scope="col">Ostatnia aktualizacja</th>
                            <th scope="col">Akcje</th>
                        </tr>
                    </MDBTableHead>
                        <MDBTableBody>
                            {this.renderDrafts()}
                        </MDBTableBody>
                    </MDBTable><br/>
                    <h3>Opublikowane posty</h3>
                    <MDBTable className="text-center">
                    <MDBTableHead>
                        <tr>
                            <th scope="col">Nazwa posta</th>
                            <th scope="col">Autor</th>
                            <th scope="col">Wstęp</th>
                            <th scope="col">Akcje</th>
                        </tr>
                    </MDBTableHead>
                        <MDBTableBody>
                            {this.renderPosts()}
                        </MDBTableBody>
                        
                    </MDBTable>
                    <nav aria-label='...'>
                        <MDBPagination>
                            {this.renderPagination()}
                        </MDBPagination>
                    </nav>
                </MDBContainer>
        {this.RenderConfirmation()}
        </>
        );
    }
}

export {PostListPage};