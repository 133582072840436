import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class LogoutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logout: 0
        };
    }

    componentDidMount() {
        localStorage.removeItem("token");
        this.setState({
            logout: 1
        });
    }

    render() {
        if(this.state.logout === 0) {
            return <div><p>Trwa wylogowywanie...</p></div>
        } else {
            return <div><Navigate to="/login"></Navigate></div>
        }
    }
}

export { LogoutPage };