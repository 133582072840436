import React, {Component} from "react";
import { Nav } from "../components/nav";
import { Link } from "react-router-dom";
import { MDBBtn, MDBContainer, MDBTable, MDBTableHead, MDBTableBody} from "mdb-react-ui-kit";

class Comments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: 0,
            error: 0,
            acceptedComments: [],
            pendingComments: [],
            blockedComments: []
        }
        let tok = localStorage.getItem("token");
        if (tok === null || tok.length < 300) {
            localStorage.removeItem("token");
            localStorage.removeItem("name");
            window.location.replace("/login");
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        let tokentoget = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/comments/waiting", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({pendingComments: response.data})});

        fetch("https://api.blog.programix.ct8.pl/api/admin/comments/accepted", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({acceptedComments: response.data})});

        fetch("https://api.blog.programix.ct8.pl/api/admin/comments/blocked", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({blockedComments: response.data, isLoaded: 1})});
    }

    renderPending = () => {
        return this.state.pendingComments.map(comment => (<tr key={comment.id}>
                    <td>{comment.post_name}</td>
                    <td>{comment.comment}</td>
                    <td>{comment.created_at}</td>
                    <td>
                        <Link to={"/comment/edit/" + comment.id} className="float-start">Edytuj</Link>
                        <MDBBtn color="success" className="float-center" onClick={(event) => this.publish(event, comment.id)}>Zaakceptuj</MDBBtn>
                        <MDBBtn color="danger" className="float-end" onClick={(event) => this.block(event, comment.id)}>Zablokuj</MDBBtn>
                    </td>
                </tr>));
    }

    renderAccepted = () => {
        return this.state.acceptedComments.map(comment => (<tr key={comment.id}>
                    <td>{comment.post_name}</td>
                    <td>{comment.comment}</td>
                    <td>{comment.created_at}</td>
                    <td>
                        <Link to={"/comment/edit/" + comment.id}>Edytuj</Link>
                    </td>
                </tr>));
    }

    renderBlocked = () => {
        return this.state.blockedComments.map(comment => (<tr key={comment.id}>
                    <td>{comment.post_name}</td>
                    <td>{comment.comment}</td>
                    <td>{comment.created_at}</td>
                </tr>));
    }

    publish(event, id) {
        let tokentopublish = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/comments/publish/" + id, {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentopublish
            }
        })
         .then(function(response) {
            return response.json();
         })
         .then((response) => {
            console.log(response);
            this.loadData();
         })
    }

    block(event, id) {
        let tokentoblock = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/comments/block/" + id, {
            method: "POST",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoblock
            }
        })
         .then(function(response) {
            return response.json();
         })
         .then((response) => {
            console.log(response);
            this.loadData();
         })
    }

    render() {
        if (this.state.error === 1) {
            return <>
                    <Nav/>
                    <center><h1>Błąd!</h1></center>
                   </>
        } else if (this.state.isLoaded === 0) {
            return <>
                        <Nav/>
                        <MDBContainer className="mt-4">
                            <center><h1>Ładowanie...</h1></center>
                        </MDBContainer>
                    </>
        } else {
            return (
                <>
                    <Nav/>
                    <MDBContainer className="mt-4">
                        <center><h1>Lista komentarzy</h1></center>
                        <h3>Komentarze oczekujące</h3>
                        <i>Komentarze mogą zostać zablokowane automatycznie przez filtr.</i>
                        <MDBTable className="text-center">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">Do posta</th>
                                <th scope="col">Treść</th>
                                <th scope="col">Data</th>
                                <th scope="col">Akcje</th>
                            </tr>
                        </MDBTableHead>
                            <MDBTableBody>
                                {this.renderPending()}
                            </MDBTableBody>
                        </MDBTable><br/>
                        <h3>Komentarze zaakceptowane</h3>
                        <MDBTable className="text-center">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">Do posta</th>
                                <th scope="col">Treść</th>
                                <th scope="col">Data</th>
                                <th scope="col">Akcje</th>
                            </tr>
                        </MDBTableHead>
                            <MDBTableBody>
                                {this.renderAccepted()}
                            </MDBTableBody>
                        </MDBTable><br/>
                        <h3>Komentarze zablokowane</h3>
                        <MDBTable className="text-center">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">Do posta</th>
                                <th scope="col">Treść</th>
                                <th scope="col">Data</th>
                            </tr>
                        </MDBTableHead>
                            <MDBTableBody>
                                {this.renderBlocked()}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBContainer>
            </>
            );
        }
    }
}

export {Comments};