import React, {Component} from "react";
import { Nav } from "../components/nav";
import { MDBContainer, MDBTable, MDBTableHead, MDBTableBody} from "mdb-react-ui-kit";

class StatsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postsStats: [],
            commentsStats: [],
            isLoaded: 0
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        let tokentoget = localStorage.getItem("token");
        fetch("https://api.blog.programix.ct8.pl/api/admin/stats/posts", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({postsStats: response.data})});
        
          fetch("https://api.blog.programix.ct8.pl/api/admin/stats/comments", {
            method: "GET",
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer' + tokentoget
            }
        })
          .then(res => res.json())
          .then(response => {this.setState({commentsStats: response.data, isLoaded: 1})});
    }

    listPosts = () => {
        return this.state.postsStats.map(post => (
                            <tr key={post.id}>
                                <td>{post.route}</td>
                                <td>{post["01"]}</td>
                                <td>{post['02']}</td>
                                <td>{post['03']}</td>
                                <td>{post['04']}</td>
                                <td>{post['05']}</td>
                                <td>{post['06']}</td>
                                <td>{post['07']}</td>
                                <td>{post['08']}</td>
                                <td>{post['09']}</td>
                                <td>{post['0']}</td>
                                <td>{post['1']}</td>
                                <td>{post['2']}</td>
                            </tr>
        ));
    }

    listComments = () => {
        return this.state.commentsStats.map(post => (
                            <tr key={post.id}>
                                <td>{post.route}</td>
                                <td>{post["01"]}</td>
                                <td>{post['02']}</td>
                                <td>{post['03']}</td>
                                <td>{post['04']}</td>
                                <td>{post['05']}</td>
                                <td>{post['06']}</td>
                                <td>{post['07']}</td>
                                <td>{post['08']}</td>
                                <td>{post['09']}</td>
                                <td>{post['0']}</td>
                                <td>{post['1']}</td>
                                <td>{post['2']}</td>
                            </tr>
        ));
    }

    render() {
        if (this.state.isLoaded === 1) {return (
            <>
                <Nav/>
                <MDBContainer>
                    <center><h1 className="mt-2">Statystyka ogólna</h1></center>
                    <h3>Posty</h3>
                    <MDBTable className="text-center">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">Droga</th>
                                <th scope="col">Styczeń</th>
                                <th scope="col">Luty</th>
                                <th scope="col">Marzec</th>
                                <th scope="col">Kwiecień</th>
                                <th scope="col">Maj</th>
                                <th scope="col">Czerwiec</th>
                                <th scope="col">Lipiec</th>
                                <th scope="col">Sierpień</th>
                                <th scope="col">Wrzesień</th>
                                <th scope="col">Październik</th>
                                <th scope="col">Listopad</th>
                                <th scope="col">Grudzień</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {this.listPosts()}
                        </MDBTableBody>
                    </MDBTable>
                    <h3>Komentarze</h3>
                    <MDBTable className="text-center">
                        <MDBTableHead>
                            <tr>
                                <th scope="col">Droga</th>
                                <th scope="col">Styczeń</th>
                                <th scope="col">Luty</th>
                                <th scope="col">Marzec</th>
                                <th scope="col">Kwiecień</th>
                                <th scope="col">Maj</th>
                                <th scope="col">Czerwiec</th>
                                <th scope="col">Lipiec</th>
                                <th scope="col">Sierpień</th>
                                <th scope="col">Wrzesień</th>
                                <th scope="col">Październik</th>
                                <th scope="col">Listopad</th>
                                <th scope="col">Grudzień</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {this.listComments()}
                        </MDBTableBody>
                    </MDBTable>
                </MDBContainer>
            </>
        );} else {
            return (
                <>
                <Nav/>
                <MDBContainer>
                    <center><h1 className="mt-2">Ładowanie...</h1></center>
                </MDBContainer>
                </>
            );
        }
    }
}

export default StatsPage;